import * as React from 'react';
import { Link } from 'gatsby';

import { PAGES } from '../config/constants';
import Layout from '../components/layout';
import Heading from '../components/heading';

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Not found" breadcrumbTrail={[PAGES.NOT_FOUND]}>
      <Heading>Page not found</Heading>
      <p>Sorry we couldn’t find what you were looking for.</p>
      <p>Go to the <Link to="/">home page.</Link></p>
    </Layout>
  );
};

export default NotFoundPage;
